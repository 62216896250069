<template>
  <div v-if="loading">{{ $gettext('Caricamento dei dati della notizie...') }}</div>

  <div v-if="!loading" class="news-wrapper container">
    <div class="news__title">
      <h1 v-html="title"></h1>
    </div>
    <div class="news">
      <div class="img">
        <img :src="headerImage" />
      </div>
      <div id="body" v-html="body"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      headerImage: this.$imgPlaceHolder,
      title: '',
      body: '',
    };
  },

  name: 'machine-details',

  methods: {
    domDecoder(str) {
      let parser = new DOMParser();
      let dom = parser.parseFromString(
        '<!doctype html><body>' + str,
        'text/html',
      );
      return dom.body.textContent;
    },
    async getImage(id) {
      await this.axios
        .get(this.api.getMediaUrl + id)
        .then((res) => {
          this.headerImage = res.data.media_details.sizes.full.source_url;
        })
        .catch((err) => {
          console.log('Errror: ' + err);
        });
    },
    getNewsData() {
      this.loading = true;

      this.axios
        .get(this.api.newsDetails + this.id+"?"+ + this.$store.getters['auth/getLanguageRest'])
        .then((res) => {
          this.title = this.domDecoder(res.data.title.rendered);
          //this.body = this.domDecoder(res.data.content.rendered);
          this.body = res.data.content.rendered;

          this.getImage(res.data.featured_media);

          this.loading = false;
        })
        .catch((err) => {
          console.log('Errror: ' + err);
        });
    },
  },

  created() {
    this.id = this.$route.params.id;
  },

  mounted() {
    this.getNewsData();
  },
};
</script>

<style scoped>
.news {
  text-align: left;
}

.news__title {
  width: 100%;
  float: left;
  background-color: var(--var-primary-color);
}

.news__title h1 {
  padding: 40px 10px;
  color: white;
  font-size: 2rem;
  letter-spacing: 4px;
  text-transform: uppercase;
  margin: 0;
}

.container {
  width: 100%;
}

img {
  width: 100%;
}

#body {
  margin-bottom: 30px;
  padding: 0 30px;
  font-size: 1.25rem;
  line-height: 150%;
}

.img {
  padding: 20px 0;
}
</style>
